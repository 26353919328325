import { useNavigate } from "react-router-dom";
import Styles from "./HelpButton.module.css";

const HelpButton = () => {
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <div className="dropdown">
      <button
        className={`btn nav-link text-white dropdown-toggle ${Styles["help-button"]}`}
        id="helpDropdown"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <i className="bi bi-question-circle"></i>
        <span className="ms-1">Help</span>
      </button>
      <ul
        className="dropdown-menu dropdown-menu-end bg-dark text-white"
        aria-labelledby="helpDropdown"
      >
        <li>
          <button
            className="dropdown-item d-flex align-items-center gap-2 text-white"
            onClick={() => handleNavigate("/help/new")}
          >
            <i className="bi bi-pencil-square"></i> Create New Ticket
          </button>
        </li>
        <li>
          <button
            className="dropdown-item d-flex align-items-center gap-2 text-white"
            onClick={() => handleNavigate("/help/tickets")}
          >
            <i className="bi bi-card-list"></i> My Tickets
          </button>
        </li>
      </ul>
    </div>
  );
};

export default HelpButton;
