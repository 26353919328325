import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "src/components/Loading";
import { HttpMethod, callApi } from "src/services/apiService";

interface Ticket {
  id: number;
  subject: string;
  status: string;
  updatedAt: string;
  createdAt?: string;
  lastCommentAt?: string;
}

type SortKey = keyof Pick<Ticket, "id" | "subject" | "status" | "updatedAt">;

const ListTickets = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [showAll, setShowAll] = useState(false);
  const [sortKey, setSortKey] = useState<SortKey>("id");
  const [sortDesc, setSortDesc] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    fetchTickets();
    // eslint-disable-next-line
  }, [page, showAll]);

  const fetchTickets = async () => {
    setIsLoading(true);
    try {
      const apiUrl = `help/my-tickets?page=${page}&pageSize=${pageSize}&all=${showAll}`;
      const response = await callApi(apiUrl, HttpMethod.GET);
      if (response) {
        setTickets(response.tickets);
        setTotalCount(response.totalCount);
      }
    } catch (error) {
      console.error("There has been a problem fetching tickets:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const totalPages = Math.ceil(totalCount / pageSize);

  const handleToggleShowAll = () => {
    setShowAll(!showAll);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleSort = (key: SortKey) => {
    if (key === sortKey) {
      setSortDesc(!sortDesc);
    } else {
      setSortKey(key);
      setSortDesc(true);
    }
  };

  const sortedTickets = [...tickets].sort((a, b) => {
    const aValue = a[sortKey];
    const bValue = b[sortKey];

    if (typeof aValue === "number" && typeof bValue === "number") {
      return sortDesc ? bValue - aValue : aValue - bValue;
    }
    if (typeof aValue === "string" && typeof bValue === "string") {
      return sortDesc
        ? bValue.localeCompare(aValue)
        : aValue.localeCompare(bValue);
    }
    return 0;
  });

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1>My Tickets</h1>
      </div>

      <div className="mb-3 d-flex justify-content-between align-items-center">
        <Link to="/help/new" className="btn btn-primary">
          Create New Ticket
        </Link>

        <label className="form-check-label mb-0">
          Show all tickets
          <input
            type="checkbox"
            className="form-check-input ms-2"
            checked={showAll}
            onChange={handleToggleShowAll}
          />
        </label>
      </div>

      {tickets.length === 0 ? (
        <p>No tickets found.</p>
      ) : (
        <table className="table table-hover">
          <thead>
            <tr>
              <th
                onClick={() => handleSort("id")}
                style={{ cursor: "pointer" }}
              >
                ID {sortKey === "id" && (sortDesc ? "▼" : "▲")}
              </th>
              <th
                onClick={() => handleSort("subject")}
                style={{ cursor: "pointer" }}
              >
                Subject {sortKey === "subject" && (sortDesc ? "▼" : "▲")}
              </th>
              <th
                onClick={() => handleSort("status")}
                style={{ cursor: "pointer" }}
              >
                Status {sortKey === "status" && (sortDesc ? "▼" : "▲")}
              </th>
              <th
                onClick={() => handleSort("updatedAt")}
                style={{ cursor: "pointer" }}
              >
                Last Updated {sortKey === "updatedAt" && (sortDesc ? "▼" : "▲")}
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedTickets.map((ticket) => (
              <tr
                key={ticket.id}
                style={{ cursor: "pointer" }}
                onClick={() => navigate(`/help/ticket/${ticket.id}`)}
              >
                <td>{ticket.id}</td>
                <td className="text-primary text-decoration-underline">
                  {ticket.subject}
                </td>
                <td>{ticket.status}</td>
                <td>{new Date(ticket.updatedAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-secondary"
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>

        <span>
          Page {page} of {totalPages}
        </span>

        <button
          className="btn btn-secondary"
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ListTickets;
