import { useState, useEffect, useCallback } from "react";
import * as React from "react";
import { useParams } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import axios, { AxiosProgressEvent } from "axios";
import { HttpMethod, callApi } from "src/services/apiService";
import Loading from "src/components/Loading";
import { useUploadListContext } from "src/contexts/UploadListContext";
import { useAppSelector } from "src/app/hooks";
import { selectAuth } from "src/features/authSlice";
import FileUploadContainer from "src/components/FileUploadContainer";
import ProgressBar from "src/components/ProgressBar";
import Warning from "src/components/Warning";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { CDRSession } from "./types";
import Sessions from "./Sessions";
import CollapsibleCard from "src/components/CollapsibleCard";

const CDRAnalysis: React.FC = () => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { setProjectId, setCampaignId } = useUploadListContext();
  const { userName, acctId, userId } = useAppSelector(selectAuth);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<any>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [sessions, setSessions] = useState<CDRSession[]>([]);
  const [uploadedFileName, setUploadedFileName] = useState<string>("");

  useEffect(() => {
    // Set the campaign ID in the context when the component mounts
    if (campaignId) {
      setCampaignId(campaignId);
    }
  }, [campaignId, setCampaignId]);

  // Define fetchCDRData outside of useEffect to make it accessible to onDrop
  const fetchCDRData = useCallback(async () => {
    setIsLoading(true);
    try {
      // This is a placeholder for the actual API call
      // Replace with the actual endpoint when available
      // const apiUrl = `main/CDRAnalysis?campaignId=${campaignId}`;
      // const response = await callApi(apiUrl, HttpMethod.GET);
      // if (response) {
      //   setData(response);
      //   setSessions(response.sessions || []);
      // }

      // For now, we'll simulate a successful API response with mock data
      setTimeout(() => {
        // Generate mock sessions
        const mockSessions: CDRSession[] = [];

        // If we have a newly uploaded file, add it to the beginning of the list
        if (uploadedFileName) {
          mockSessions.push({
            sessionId: Date.now(), // Use timestamp as a unique ID
            fileName: uploadedFileName,
            uploadDate: new Date().toLocaleString(),
            uploadedBy: "Ronnie Allen",
            bitmap: 3, // Administrator bitmap
            totalCalls: Math.floor(Math.random() * 2000) + 500,
            connectedCalls: Math.floor(Math.random() * 1500) + 300,
            averageDuration: `${Math.floor(Math.random() * 5) + 1}m ${Math.floor(Math.random() * 59) + 1}s`,
            anserRate: `${Math.floor(Math.random() * 30) + 60}%`,
            avgDailyCallsPerANI: `${(Math.random() * 100).toFixed(0)}`,
            durationViolations: {
              multipleCallsIn24Hours: Math.floor(Math.random() * 50),
              twoCallsIn15Minutes: Math.floor(Math.random() * 25),
              threeCallsInOneHour: Math.floor(Math.random() * 30),
            },
            callingWindowViolations: Math.floor(Math.random() * 20),
            reassignedNumberCalls: Math.floor(Math.random() * 15),
            sipCodeDistribution: {
              "200": Math.floor(Math.random() * 200) + 100,
              "486": Math.floor(Math.random() * 50) + 10,
              "487": Math.floor(Math.random() * 30) + 5,
              "404": Math.floor(Math.random() * 20) + 5,
              "603": Math.floor(Math.random() * 15) + 5,
            },
            callDispositions: {
              total: Math.floor(Math.random() * 2000) + 500,
              connected: Math.floor(Math.random() * 1000) + 200,
              noAnswer: Math.floor(Math.random() * 500) + 100,
              voicemail: Math.floor(Math.random() * 300) + 50,
              wrongNumber: Math.floor(Math.random() * 100) + 10,
              other: Math.floor(Math.random() * 100) + 10,
            },
            callsPerDayPerANI: {
              dates: ["3/1", "3/2", "3/3", "3/4", "3/5"],
              data: [
                {
                  ani: "5039367187",
                  callCounts: [
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                  ],
                },
                {
                  ani: "5039367188",
                  callCounts: [
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                  ],
                },
              ],
            },
          });
        }

        // Generate additional mock sessions
        const fileTypes = [".csv", ".xlsx", ".txt", ".zip"];
        const userNames = ["Ronnie Allen"];
        const bitmaps = [3, 1, 2, 1, 2]; // 3 is administrator, 1 is agent, 2 is supervisor

        for (let i = 1; i <= 15; i++) {
          const fileType =
            fileTypes[Math.floor(Math.random() * fileTypes.length)];
          const date = new Date();
          date.setDate(date.getDate() - i); // Each file is uploaded a day before the previous one
          const userIndex = Math.floor(Math.random() * userNames.length);

          mockSessions.push({
            sessionId: i,
            fileName: `campaign_cdr_${date.toISOString().split("T")[0].replace(/-/g, "")}_${i}${fileType}`,
            uploadDate: date.toLocaleString(),
            uploadedBy: userNames[userIndex],
            bitmap: bitmaps[userIndex],
            totalCalls: Math.floor(Math.random() * 2000) + 500,
            connectedCalls: Math.floor(Math.random() * 1500) + 300,
            averageDuration: `${Math.floor(Math.random() * 5) + 1}m ${Math.floor(Math.random() * 59) + 1}s`,
            anserRate: `${Math.floor(Math.random() * 30) + 60}%`,
            avgDailyCallsPerANI: `${(Math.random() * 100).toFixed(0)}`,
            durationViolations: {
              multipleCallsIn24Hours: Math.floor(Math.random() * 50),
              twoCallsIn15Minutes: Math.floor(Math.random() * 25),
              threeCallsInOneHour: Math.floor(Math.random() * 30),
            },
            callingWindowViolations: Math.floor(Math.random() * 20),
            reassignedNumberCalls: Math.floor(Math.random() * 15),
            sipCodeDistribution: {
              "200": Math.floor(Math.random() * 200) + 100,
              "486": Math.floor(Math.random() * 50) + 10,
              "487": Math.floor(Math.random() * 30) + 5,
              "404": Math.floor(Math.random() * 20) + 5,
              "603": Math.floor(Math.random() * 15) + 5,
            },
            callDispositions: {
              total: Math.floor(Math.random() * 2000) + 500,
              connected: Math.floor(Math.random() * 1000) + 200,
              noAnswer: Math.floor(Math.random() * 500) + 100,
              voicemail: Math.floor(Math.random() * 300) + 50,
              wrongNumber: Math.floor(Math.random() * 100) + 10,
              other: Math.floor(Math.random() * 100) + 10,
            },
            callsPerDayPerANI: {
              dates: ["3/1", "3/2", "3/3", "3/4", "3/5"],
              data: [
                {
                  ani: `503936${Math.floor(Math.random() * 10000)}`,
                  callCounts: [
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                  ],
                },
                {
                  ani: `503936${Math.floor(Math.random() * 10000)}`,
                  callCounts: [
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                  ],
                },
              ],
            },
          });
        }

        const mockData = {
          title: "CDR Analysis",
          summary: {
            totalCalls: mockSessions.reduce(
              (sum, session) => sum + session.totalCalls,
              0,
            ),
            connectedCalls: mockSessions.reduce(
              (sum, session) => sum + session.connectedCalls,
              0,
            ),
            averageDuration: "3m 45s", // This would be calculated in a real implementation
            successRate: "70%", // This would be calculated in a real implementation
          },
          sessions: mockSessions,
        };

        setData(mockData);
        setSessions(mockSessions);
        setUploadedFileName(""); // Clear the uploaded file name after processing
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Failed to fetch CDR analysis data:", error);
      setIsLoading(false);
    }
  }, [campaignId, uploadedFileName, userName]);

  useEffect(() => {
    if (campaignId) {
      fetchCDRData();
    }
  }, [campaignId, fetchCDRData]);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];
      setErrorMessage(""); // Clear any previous errors
      setUploadedFileName(file.name); // Store the file name

      if (!campaignId) {
        setErrorMessage(
          "Cannot read campaignId. campaignId is required for CDR upload",
        );
        return;
      }

      try {
        // Step 1: Get SAS URL for upload
        const initiateResponse = await callApi(
          "cdranalysis/initiate-upload",
          HttpMethod.POST,
          {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            userId: userId,
            acctId: acctId,
            campaignId: campaignId,
          },
        );

        if (!initiateResponse?.uploadUrl) {
          setErrorMessage("Failed to get upload URL to Azure Storage");
          throw new Error("Failed to get upload URL");
        }

        // Step 2: Upload file directly to blob storage using SAS URL
        // I am using Axios since that make it easy to see upload progress
        await axios({
          method: "put",
          url: initiateResponse.uploadUrl,
          data: file,
          headers: {
            "x-ms-blob-type": "BlockBlob",
            "Content-Type": file.type,
          },
          onUploadProgress: (progressEvent: AxiosProgressEvent) => {
            const total = progressEvent.total || progressEvent.loaded;
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / total,
            );
            setUploadProgress(percentCompleted);
          },
        });

        console.log("CDR file uploaded successfully");
        setUploadProgress(0);

        // Refresh data after successful upload
        fetchCDRData();
      } catch (error: any) {
        console.error("There was an error uploading the CDR file.", error);
        setUploadProgress(0);
        setErrorMessage(
          error.response?.data?.message ||
            "There was an error uploading your CDR file. Please try again or contact support if the issue persists.",
        );
      }
    },
    [campaignId, fetchCDRData, userId, acctId],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  if (isLoading) return <Loading />;

  // Tooltip content
  const cdrTooltip = (
    <Tooltip id="cdr-tooltip">
      <div style={{ textAlign: "left", maxWidth: "300px" }}>
        <strong>Call Detail Records (CDRs)</strong> are data records produced by
        telecommunications systems that document the details of phone calls
        including phone numbers, call times, durations, and outcomes. Analyzing
        CDRs helps optimize campaign performance and ensure compliance.
      </div>
    </Tooltip>
  );

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="d-flex align-items-center">
            <h1 className="mb-0">Call Detail Records Analysis</h1>
            <OverlayTrigger placement="right" overlay={cdrTooltip}>
              <i className="bi bi-info-circle ms-2"></i>
            </OverlayTrigger>
          </div>
          <p>
            Upload your Call Detail Records (CDRs) to be analyzed to ensure
            compliance, reduce spam call labeling, and optimize contact rates.
          </p>

          {/* File Upload Section */}
          <div className="row mb-4">
            <div className="col-md-12 col-lg-6">
              {uploadProgress > 0 && <ProgressBar progress={uploadProgress} />}
              {errorMessage && <Warning message={errorMessage} />}
              <div className="d-flex gap-2 mt-4 flex-wrap">
                <FileUploadContainer
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  isDragActive={isDragActive}
                  title="UPLOAD CDR FILE"
                  iconClassName="bi-box-arrow-up"
                />
              </div>
            </div>

            {/* File format instructions */}
            <div className="col-md-12 col-lg-6">
              <CollapsibleCard
                title="CDR File Format Instructions"
                icon="bi-info-circle"
                defaultExpanded={true}
                className="rounded"
              >
                <p>
                  Only upload outbound Call Detail Records. Your uploaded file
                  can be either CSV or XLSX. Your Call Details Record (CDR) file
                  should include the following columns:
                </p>
                <ul>
                  <li>
                    <strong>Phone Number</strong> - The phone number dialed
                  </li>
                  <li>
                    <strong>ANI</strong> - Automatic Number Identification
                    (Caller ID used to place the call)
                  </li>
                  <li>
                    <strong>Call Start Time</strong> - When the call began
                  </li>
                  <li>
                    <strong>Call End Time</strong> - When the call ended
                  </li>
                  <li>
                    <strong>Call Disposition</strong> - Agent-entered outcome of
                    the call
                  </li>
                  <li>
                    <strong>Carrier SIP Response Code</strong> - Response code
                    from the carrier
                  </li>
                  <li>
                    <strong>Call Length</strong> - Duration in HH:MM:SS format
                    or integer seconds.
                  </li>
                </ul>
                <p>For large datasets, ZIP compression is supported.</p>
                <p>
                  Need help? Contact our Help Desk for assistance with
                  formatting your CDR files.
                </p>
              </CollapsibleCard>
            </div>
          </div>

          {/* Session List */}
          {sessions.length > 0 ? (
            <Sessions sessions={sessions} />
          ) : (
            <div className="alert alert-warning">
              <i className="bi bi-exclamation-triangle me-2"></i>
              No CDR data available for this campaign. Please upload a CDR file
              to begin analysis.
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CDRAnalysis;
