import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { callApi, HttpMethod } from "src/services/apiService";
import Loading from "src/components/Loading";

interface TicketComment {
  id: number;
  body: string;
  authorId: number;
  createdAt: string;
}

interface TicketDetails {
  id: number;
  subject: string;
  description: string;
  status: string;
  createdAt: string;
  updatedAt: string;
  comments: TicketComment[];
}

const TicketDetails = () => {
  const { ticketId } = useParams<{ ticketId: string }>();
  const [ticket, setTicket] = useState<TicketDetails | null>(null);
  const [newComment, setNewComment] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (ticketId) {
      fetchTicketDetails(ticketId);
    }
    // eslint-disable-next-line
  }, [ticketId]);

  const fetchTicketDetails = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await callApi(`help/ticket/${id}`, HttpMethod.GET);
      if (response) {
        setTicket(response);
      }
    } catch (error) {
      console.error("Error fetching ticket details:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddComment = async () => {
    if (!newComment.trim() || !ticketId) return;

    setIsSubmitting(true);
    try {
      await callApi(`help/ticket/${ticketId}/comment`, HttpMethod.POST, {
        key: "",
        value: newComment,
      });

      setNewComment("");
      await fetchTicketDetails(ticketId);
    } catch (err) {
      console.error("Error submitting comment:", err);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isLoading || !ticket) {
    return <Loading />;
  }

  return (
    <div className="container-fluid mb-5">
      <h1>Ticket Details</h1>

      <div className="card mb-4">
        <div className="card-header d-flex justify-content-between align-items-center">
          <span>{ticket.subject}</span>
          <span
            className={`text-uppercase ${
              ticket.status === "open" ? "text-primary" : "text-danger"
            }`}
          >
            {ticket.status}
          </span>
        </div>
        <div className="card-body">
          <p
            dangerouslySetInnerHTML={{
              __html: ticket.description.replace(/\n/g, "<br />"),
            }}
          />
          <div className="row mt-3">
            <div className="col-6">
              <small>
                Created: {new Date(ticket.createdAt).toLocaleString()}
              </small>
            </div>
            <div className="col-6 text-end">
              <small>
                Last Updated: {new Date(ticket.updatedAt).toLocaleString()}
              </small>
            </div>
          </div>
        </div>
      </div>

      {ticket.comments.length === 0 ? (
        <p>Awaiting response from support.</p>
      ) : (
        <div className="mt-4 d-flex flex-column gap-3">
          {ticket.comments.slice(1).map((comment) => {
            const isSupport = comment.authorId !== ticket.comments[0].authorId;

            return (
              <div
                key={comment.id}
                className={`p-3 rounded border shadow-sm ${
                  isSupport ? "bg-primary bg-opacity-10" : "bg-light"
                }`}
              >
                <div className="mb-1 fw-semibold">
                  {isSupport ? "Support" : "You"}
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: comment.body.replace(/\n/g, "<br />"),
                  }}
                />

                <div
                  className="text-end mt-2"
                  style={{ fontSize: "0.8rem", color: "#888" }}
                >
                  {new Date(comment.createdAt).toLocaleString()}
                </div>
              </div>
            );
          })}
        </div>
      )}

      <hr />
      <h4 className="mt-4">Add a Comment</h4>
      <textarea
        className="form-control mb-2"
        rows={4}
        value={newComment}
        onChange={(e) => setNewComment(e.target.value)}
        placeholder="Type your comment here..."
      />
      <button
        className="btn btn-primary"
        onClick={handleAddComment}
        disabled={isSubmitting || !newComment.trim()}
      >
        {isSubmitting ? "Submitting..." : "Submit Comment"}
      </button>

      <button
        className="btn btn-secondary ms-2"
        onClick={() => navigate("/help/tickets")}
      >
        Close
      </button>
    </div>
  );
};

export default TicketDetails;
